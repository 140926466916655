@media only screen and (max-width:576px) {
.stream
{
    margin-left:   auto !important;
    margin-bottom: auto !important;
}
.we
{
  width: 100% !important;
  margin-top: 40px !important;
  /* text-align: center; */
}

    
    
}
@media only screen and (max-width:400px) {
  .stream
  {
      margin-left:   auto !important;
      margin-bottom: auto !important;
  }
  .we
  {
    width: 100% !important;
    margin-top: 40px !important;
    /* text-align: center; */
  }
  
      
      
  }
  @media only screen and (max-width:300px) {
    .stream
    {
        margin-left:   auto !important;
        margin-bottom: auto !important;
    }
    .we
    {
      width: 100% !important;
      margin-top: 40px !important;
      /* text-align: center; */
    }
    
        
        
    }
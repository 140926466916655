

  
  /* Toggle Styles */
  
  /* #viewport {
    padding-left: 250px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  } */
  
  /* #content {
    width: 100%;
    position: relative;
    margin-right: 0;
  }
   */
  /* Sidebar Styles */
  
  #sidebar {
    /* z-index: 1000; */
    /* position: absolute; */
    /* left: 250px; */
    width: 350px;
    height: auto;
    /* margin-left: -250px; */
    /* overflow-y: auto; */
    /* background: #37474F; */
    /* -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; */
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
  }
  
  #sidebar header {
    /* background-color: #263238; */
    font-size: 20px;
    line-height: 52px;
    /* text-align: center; */
  }
  
  #sidebar header a {
    color: rgb(0, 0, 0);
    display: block;
    text-decoration: none;
  }
  
  #sidebar header a:hover {
    /* color: #fff; */
  }
  
  #sidebar .nav{
    
  }
  
  #sidebar .nav a{
    background: none;
    border-bottom: 1px solid #455A64;
    color: #CFD8DC;
    font-size: 14px;
    padding: 16px 24px;
  }
  

  
  #sidebar .nav a i{
    margin-right: 16px;
  }
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,300&display=swap');

:root {
    /* Colors */

    --primary: #a8203b;
    --primary-light: #c44464;
    --dark-brown: #341413;
    --gray-1: #798092;
    --gray-2: #E6E6ED;
    --white: #FFFFFF;

    /* font-size */

    --header-1: 64px;
    --header-2: 48px;
    --header-3: 42px;
    --header-4: 32px;

    --text-medium: 18px;
    --text-regular: 16px;
    --text-light: 13px;

    /* font-weight */

    --font-weight-bold: 700;
    --font-weight-semi-bold: 600;
    --font-weight-medium: 500;
    --font-weight-reular: 400;
    --font-weight-light: 300;

}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


/* button primary */

.btn {
    padding: 15px 25px;
    border-radius: 6px;
    font-size: var(--text-medium);
    font-weight: var(--font-weight-medium);
}

.btn-primary {
    background-color: var(--primary);
    border: none;
}

.btn-primary:focus {
    background-color: var(--primary-light);

}

.btn-primary:hover {
    background-color: var(--primary-light);
}

.text-label {
    text-shadow: 0 10px 10px rgba(0, 0, 0, 0.606);

}

.cta .but:hover {
    background-color: #a8203b !important;
    color: white !important;
}

/* button secondary */

.btn-secondary {
    background-color: var(--gray-2);
    color: var(--gray-1);
    border: none;
}

.btn-secondary:focus {
    background-color: var(--white);
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.05);
    color: var(--gray);
}

.btn-secondary:hover {
    background-color: var(--gray-2) !important;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.05);
    color: var(--gray-1);
}

.navbar-brand img {
    padding-right: 16px;

}

.navbar-brand span {
    font-size: var(--text-medium);
    font-weight: var(--font-weight-medium);
    color: var(--dark-brown);
}

.navbar-toggler:focus {
    outline: none;
}

.navbar {
    padding: 15px 0;
    /* position: relative; */
    z-index: 200;
}

Link.nav-link {
    margin-right: 39px;
    font-size: 14px !important;
    font-weight: var(--font-weight-regular);
    color: var(--dark-brown);
}

section.hero {
    margin: 2rem 0;
    margin-top: 15px;

}

.text-label,
.text-hero-bold,
.text-hero-regular {
    margin: 20px 0;
}

.text-label {
    color: var(--primary);
    font-size: var(--text-regular);
    font-weight: var(--font-weight-regular);
    /* line-height: 31px; */

}

.text-hero-bold {
    color: var(--dark-brown);
    font-size: var(--header-1);
    font-weight: var(--font-weight-bold);
    line-height: 65px;
}

.text-hero-regular {
    color: var(--gray-1);
    font-size: var(--text-regular);
    font-weight: var(--font-weight-regular);
    line-height: 25px;


}

/* desktop version only */

/* @media only screen and (max-width:1399.98px) {
    .container
    {
        width: 84%;
    }
    
    
} */




/* mobile version only */

@media only screen and (max-width:576px) {
    .navbar {
        padding: 28px 0;

    }

    Link.nav-link {
        margin-right: 7px 0;
    }

    /* hero */

    section.hero {
        margin: 2rem 0;
        text-align: center;

    }

    .btn {
        display: block;
        margin: 1rem 0;
    }

    .ms-3 {
        margin-left: 0 !important;
    }

    body {
        margin-right: auto;
        margin-left: auto;
        overflow-x: hidden !important;

    }

    .text-label,
    .text-hero-bold,
    .text-hero-regular {
        text-align: center;

    }

    .text-hero-bold {
        font-weight: var(--font-weight-bold);
        font-size: var(--header-3);
        line-height: 64px;


    }

    .section.hero img {
        margin: 2rem 0;
    }

    .why {
        text-align: center;
    }

    .apply {
        text-align: center;
    }

    .inner-apply {
        display: flex !important;
    }

    .tab-content {
        padding: 2px !important;
        font-size: 13px;
    }

    .lists {
        text-align: center;
    }

    .tags {
        padding: 0px;
    }

    .partner {
        text-align: center;

    }


    .part2 .card {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .course-card {
        width: 100%;
        margin-bottom: 20px;
        border-color: #000;
        box-shadow: none;
    }



}

@media only screen and (min-width:576px) {
    body {
        overflow-x: hidden !important;
        margin-right: auto;
        margin-left: auto;

    }



}

body {
    overflow-x: hidden !important;

}

.course-card {
    margin-bottom: 20px;
    /* box-shadow: inset 0 0 0 0 #FF723A; */

}

.course-card p {
    color: #e4764a !important;

}

.offcanvas label {
    font-size: 13px !important;
}

/* .offcanvas
{
    width: 30% !important; 
} */

@media only screen and (max-width:339px) {


    .redirect {
        font-size: 0px !important;
        display: inline !important;
        text-align: center;

    }

    .teamwork img {
        height: 200px !important;
    }

}

@media only screen and (max-width:768px) {
    .but {
        display: block !important;

    }

    .timeline .left-image-div {
        width: 100% !important;
    }

    .timeline .right-content-div {
        width: 100% !important;
        padding: 0px !important;

    }

    .but2 {
        margin-left: 0px !important;
        display: block;
        margin-top: 10px;
    }


}

.navbar {
    backdrop-filter: blur(5px) !important;
    background-color: rgba(255, 255, 255, 0.383) !important;
    /* border-bottom: 1px solid #FF723A; */
}



.desti {
    /* display: flex;    */
    /* text-decoration: none; */
    /* color: inherit; */
    /* max-width: 400px; */
    /* position: relative; */
    /* padding: 2rem; */
    /* box-shadow: inset 0 0 0 4px #000, inset -4px -4px 0 6px #CCC; */
    /* background-color: #FFF; */
    cursor: pointer;
}

.desti:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    transform-origin: 0 bottom 0;
    transform: scaleY(0);
    transition: 0.10s ease-out;
}

.desti:hover .post-title {
    color: #FFF !important;
}

.desti:hover:before {
    transform: scaleY(1);
}

.post-title {
    position: relative;
    /* font-size: 2rem; */
    /* font-weight: 700; */
    /* line-height: 1.333; */
    transition: 0.4s ease-out;
}

.sibling-fade {
    visibility: hidden;
}

/* Prevents :hover from triggering in the gaps between items */

.sibling-fade>* {
    visibility: visible;
}

/* Brings the child items back in, even though the parent is `hidden` */

.sibling-fade>* {
    transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
}

/* Makes the fades smooth with a slight delay to prevent jumps as the mouse moves between items */

.sibling-fade:hover>* {
    opacity: 0.4;
    transform: scale(0.9);
}

/* Fade out all items when the parent is hovered */

.sibling-fade>*:hover {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0ms, 0ms;
}

/* Fade in the currently hovered item */
/* Presentational Styles */
.sibling-fade {
    display: flex;
    flex-wrap: wrap;
}

.sibling-fade>* {
    background: white;
    padding: 1em;
    flex: auto;
    margin: 0.3em;
    text-align: center;
    color: #f05555;
    font-size: 1.5em;
    text-decoration: none;
}


/**/

.slide_diagonal:hover {
    box-shadow: inset 400px 150px 0 0 #f03359;
    color: white !important;

}

#outer {
    /* width: 364px; */
    /* margin: 50px auto 0 auto; */
    text-align: center;
}

.button_slide {
    /* color: rgb(255, 255, 255); */
    border: 2px solid #FF723A;
    border-radius: 0px;
    /* padding: 18px 36px; */
    /* display: inline-block; */
    /* font-family: "Lucida Console", Monaco, monospace; */
    /* font-size: 14px; */
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #e8617c;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}

.sticky-icon {
    z-index: 1;
    position: fixed;
    top: 15%;
    right: 0%;
    width: 220px;
    display: flex;
    flex-direction: column;
}

.sticky-icon a {
    transform: translate(160px, 0px);
    border-radius: 50px 0px 0px 50px;
    text-align: left;
    margin: 2px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px;
    margin-top: 40px;
    font-size: 16px;
    font-family: 'Oswald', sans-serif;
    background-color: #a8203b;
    color: #fff;
    transition: all 0.8s;
}

.sticky-icon a:hover {
    /* color:#FFF; */
    background-color: #a8203b;
    transform: translate(50px, 0px);
}

.sticky-icon a:hover i {
    transform: rotate(360deg);
}

.search_icon a:hover i {
    transform: rotate(360deg);
}

.Facebook {
    background-color: #2C80D3;
    color: #FFF;
}

.Youtube {
    background-color: #fa0910;
    color: #FFF;
}

.Twitter {
    background-color: #53c5ff;
    color: #FFF;
}

.Instagram {
    background-color: lightgray;
    color: #FFF;
}

.Google {
    background-color: #d34836;
    color: #FFF;
}

.sticky-icon a i {
    background-color: #fff;
    height: 40px;
    width: 40px;
    color: rgb(0, 0, 0);
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    transition: all 0.5s;
}

.sticky-icon a i.fa-facebook-f {
    background-color: black;
    color: #2C80D3;
}

.sticky-icon a i.fa-google-plus-g {
    background-color: #FFF;
    color: #d34836;
}

.sticky-icon a i.fa-instagram {
    background-color: #FFF;
    color: rgb(164, 164, 164);
}

.sticky-icon a i.fa-youtube {
    background-color: #FFF;
    color: #fa0910;
}

.sticky-icon a i.fa-twitter {
    background-color: #FFF;
    color: #53c5ff;
}

.fas fa-shopping-cart {
    background-color: #FFF;
}

#myBtn {
    height: 50px;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    text-align: center;
    padding: 10px;
    text-align: center;
    line-height: 40px;
    border: none;
    outline: none;
    background-color: #1e88e5;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}

.fa-arrow-circle-up {
    font-size: 30px;
}

#myBtn:hover {
    background-color: #555;
}

.transfer:hover {
    background-color: #a8203b;
    color: white;
    border-color: #a8203b;
}

.dropbtn {
    /* background-color: #04AA6D; */
    /* color: white; */
    /* background-color: #f1f1f1; */

    padding: 16px;
    font-size: 15px;
    border: none;
    font-weight: 600;
}

.dropdown {
    position: relative;
    display: inline-block;

}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f5b2bd;
    min-width: 250px;

    /* box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.2); */
    z-index: 999;
    /* border-radius: 5px; */
}

.dropdown-content-call {
    display: none;
    position: absolute;
    background-color: #f5b2bd;
    min-width: 150px;
    /* box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.2); */
    z-index: 999;
    /* border-radius: 5px; */
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-weight: 600;
    transition: 3s;
}

.dropdown-content-call a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-weight: 600;
    transition: 0.5s;
}

.dropdown-content a:hover {
    background-color: #a8203b;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #f5b2bd;
    color: #FFF;
}

.dropdown:hover .dropdown-content-call {
    display: block;
}

.fa-arrow-right {
    visibility: hidden;
}

.dropdown-content a:hover .fa-arrow-right {
    visibility: visible;
    color: white;
}

::placeholder {
    font-size: 11px;
    color: rgba(148, 148, 148, 0.587);
}

.timeline .right-content-div div {
    border-radius: 10px !important;
}

.tomarrow {
    background-image: url("../images/aboutimage.png");
}

.search:focus {
    outline: none !important;
}

@media only screen and (max-width: 600px) {
    .search {
        display: none;

    }

    .timeline {
        padding: 2px !important;
    }

    .tomarrow {
        text-align: center !important;
        /* margin-left: 0px; */
    }

    .timeline .left-image-div {
        width: 100% !important;
    }

    .timeline .left-image-div img {
        width: 100% !important;
        height: 265px;
    }

    .timeline .right-content-div {
        width: 100% !important;
        padding: 0px !important;


    }

    .hero-image {
        height: 300px !important;
        width: 100% !important;
    }

    .international-bank img {
        height: 250px;
        width: 100%;
    }

    .university-card {
        height: auto !important;
    }

    .sticky-icon {
        display: none !important;
    }

    .nav-link {
        text-align: center;
    }

    .login-button {
        width: 100%;
    }

    .for-universities .col-sm-5 img {
        height: 300px !important;
    }

    .finance .col-sm-6 img {
        text-align: center !important;
        margin-left: -50px;
        height: 340px;
        /* background-color: #1e88e5; */
        /* margin-left: auto !important;
        margin-right: auto !important; */
        /* text-align: ; */

    }

    .finance h4 {
        text-align: center;
    }

    .money-back form {
        width: 100% !important;
        /* padding: 0% !important; */
        margin-top: 50px;
    }

    /* .usp-using h1 {
        padding-top: 60px !important;
    } */

    .for-universities h1 {
        margin-top: -80px;
    }

    .for-universities .order-first img {
        margin-top: -60px;
        height: 330px !important;
        margin-bottom: 35px;
    }

    .for-universities p {
        text-align: center;
    }

    .student-Recruitment .col-sm-7 p {
        text-align: center;
    }

    .student-Recruitment .col-sm-7 small {
        text-align: center !important;
    }

    .student-Recruitment h2 {
        text-align: center !important;
        margin-left: 0px;
    }


    .student-Recruitment .left-side-student img {
        height: 350px;
        margin-top: 20px;
    }

    .University-Marketing .col-sm-7 p {
        text-align: center;
    }

    .University-Marketing .col-sm-7 small {
        text-align: center !important;
    }

    .University-Marketing h2 {
        text-align: center !important;
        margin-left: 0px;
    }


    .University-Marketing .left-side-student img {
        height: 350px;
        margin-top: 20px;
    }

    .partner1 img {
        width: 100%;
        height: 300px;
    }

}

@media only screen and (max-width: 300px) {

    .usp-using .col-sm-6 .row {
        width: 100% !important;
        height: auto !important;
        text-align: center;
        margin-left: auto !important;
        margin-right: auto !important;
        /* margin-top: 200px !important; */
    }

    .usp-using {
        padding-top: 350px !important;
    }

    .money-back {
        padding: 0% !important;
    }

    .money-back form {
        width: 100% !important;
        /* padding: 0% !important; */
        margin-top: 50px;
    }

    /* .usp-using h1 {
        padding-top: 60px !important;
    } */
}

@media only screen and (max-width: 320px) {
    .search {
        display: none;

    }

    .usp-usings {
        margin-top: 350px !important;

    }

    .navbar-brand img {
        height: 25px;
    }

    .navbar-toggler-icon {
        font-size: 13px;
    }

    .international-bank img {
        height: 250px;
        width: 100%;
    }

    .university-card {
        height: auto !important;
    }

    .sticky-icon {
        display: none !important;
    }

    .nav-link {
        text-align: center;
    }

    .login-button {
        width: 100%;
    }

    .for-universities .col-sm-5 img {
        height: 300px !important;
    }

    .finance .col-sm-6 img {
        text-align: center !important;
        margin-left: -50px;
        height: 340px;
        /* background-color: #1e88e5; */
        /* margin-left: auto !important;
        margin-right: auto !important; */
        /* text-align: ; */

    }

    .finance h4 {
        text-align: center;
    }

    .money-back form {
        width: 100% !important;
        /* padding: 0% !important; */
        margin-top: 50px;
    }

    /* .usp-using h1 {
        padding-top: 60px !important;
    } */

    .for-universities h1 {
        margin-top: -80px;
    }

    .for-universities .order-first img {
        margin-top: -60px;
        height: 330px !important;
        margin-bottom: 35px;
    }

    .for-universities p {
        text-align: center;
    }

    .student-Recruitment .col-sm-7 p {
        text-align: center;
    }

    .student-Recruitment .col-sm-7 small {
        text-align: center !important;
    }

    .student-Recruitment h2 {
        text-align: center !important;
        margin-left: 0px;
    }


    .student-Recruitment .left-side-student img {
        height: 350px;
        margin-top: 20px;
    }

    .University-Marketing .col-sm-7 p {
        text-align: center;
    }

    .University-Marketing .col-sm-7 small {
        text-align: center !important;
    }

    .University-Marketing h2 {
        text-align: center !important;
        margin-left: 0px;
    }


    .University-Marketing .left-side-student img {
        height: 350px;
        margin-top: 20px;
    }

    .partner1 img {
        width: 100%;
        height: 300px;
    }

}

@media only screen and (max-width: 422px) {

    .usp-using .col-sm-6 .row {
        width: 100% !important;
        height: auto !important;
        text-align: center;
        /* margin-top: 200px !important; */
    }

    .how-it-work .col-sm-6 {
        height: auto !important;
    }

    .usp-usings {
        margin-top: 350px !important;

    }

    /* .university-card {
        height: auto !important;
    } */

    /* .how-it-work .col-sm-6 img {
            text-align: center;
        } */

    /* .usp-using {
        padding-top: 250px !important;
    } */

    /* .usp-using h1 {
        padding-top: 60px !important;
    } */
}

@media only screen and (max-width: 378px) {

    .usp-using .col-sm-6 .row {
        /* width: 100% !important; */
        height: auto !important;
        text-align: center;
        margin-left: auto !important;
        margin-right: auto !important;
        /* margin-top: 200px !important; */

    }

    /* .usp-using {
        padding-top: 350px !important;
    } */

    .money-back {
        padding: 0% !important;

    }

    .finance .col-sm-6 img {
        text-align: center !important;
        margin-left: -50px;
        height: 340px;
        /* background-color: #1e88e5; */
        /* margin-left: auto !important;
        margin-right: auto !important; */
        /* text-align: ; */

    }

    .finance h4 {
        text-align: center;
    }

    .money-back form {
        width: 100% !important;
        /* padding: 0% !important; */
        margin-top: 50px;
    }

    /* 
    .usp-using h1 {
        padding-top: 60px !important;
    } */
}

@media only screen and (max-width: 1004px) {

    .bank-account .col-sm-6 {
        width: 100% !important;
    }

    .money-back .col-sm-6 {
        width: 100% !important;
    }

    .text-section {
        width: 100% !important;
        text-align: center !important;
    }

    .usp-using .col-sm-6 {
        width: 100% !important;
    }

    .usp-usings {
        margin-top: 350px !important;
    }

    .usp-usings .col-sm-6 {
        width: 100% !important;
    }

    .how-it-work .col-sm-6 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 425px) {

    .bank-account .col-sm-6 {
        width: 100% !important;
    }

    .money-back .col-sm-6 {
        width: 100% !important;
    }

    .text-section {
        width: 100% !important;
        text-align: center !important;
    }

    .usp-using .col-sm-6 {
        width: 100% !important;

    }

    .usp-usings {
        margin-top: 350px !important;

    }

    .usp-usings .col-sm-6 {
        width: 100% !important;
        height: 400px !important;
    }

    .usp-usings .col-sm-6 .row {
        width: 100% !important;
        height: 400px !important;
        text-align: center !important;
    }

    .how-it-work .col-sm-6 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1024px) {

    .bank-account .col-sm-6 {
        width: 100% !important;
    }

    .text-section {
        width: 100% !important;
        text-align: center !important;
    }

    .usp-using .col-sm-6 {
        width: 100% !important;
    }

    .usp-usings {
        margin-top: 350px !important;
    }

    .usp-usings .col-sm-6 {
        width: 100% !important;
    }

    .how-it-work .col-sm-6 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 800px) {

    .usp-using .col-sm-6 .row {
        width: 100% !important;
        height: auto !important;
        text-align: center;
        margin-left: auto !important;
        margin-right: auto !important;
        /* margin-top: 200px !important; */
    }

    /* .usp-using {
        padding-top: 350px !important;
    } */
    .usp-usings {
        margin-top: 350px !important;

    }

    .money-back {
        padding: 0% !important;
    }

    .money-back form {
        width: 100% !important;
        /* padding: 0% !important; */
        margin-top: 50px;
    }

    /* .usp-using h1 {
        padding-top: 60px !important;
    } */
}

.pagelinks:focus {
    background-color: #a8203b !important;
}

.fa-arrow-right {
    visibility: visible !important;
}